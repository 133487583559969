import React, { useState, useEffect, useContext } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";

import StateContext from "../StateContext";

import MainMenu from "./MainMenu";
import Filtres from "./Filtres";

function Header() {
  const appState = useContext(StateContext);

  const [showMenu, setShowMenu] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  const handleCloseMenu = () => setShowMenu(false);
  const handleShowMenu = () => setShowMenu(true);

  const handleCloseFilters = () => setShowFilters(false);
  const handleShowFilters = () => setShowFilters(true);

  useEffect(() => {
    setShowMenu(false);
    setShowFilters(false);
  }, [appState.triCounter]);

  return (
    <>
      <a
        className="skip-to-content-link visually-hidden-focusable"
        href="#main-nav"
      >
        Aller au menu principal
      </a>
      <a
        className="skip-to-content-link visually-hidden-focusable"
        href="#filter-nav"
      >
        Aller au menu des filtres
      </a>
      <a
        className="skip-to-content-link visually-hidden-focusable"
        href="#introduction"
      >
        Aller au contenu
      </a>
      <nav
        id="main-nav"
        className="navbar navbar-expand-lg sticky-top"
        data-bs-theme="dark"
      >
        <div className="container-xxl">
          <div className="d-none d-lg-flex">
            <MainMenu />
          </div>
          <div className="d-flex d-lg-none w-100 justify-content-end mobile-menu-container">
            <button className="btn navbar__btn-menu" onClick={handleShowMenu}>
              <span className="visually-hidden">Menu</span>
            </button>

            <button
              className="btn btn-info rounded-5 shadow navbar__btn-filtres"
              onClick={handleShowFilters}
            >
              Filtrer les vidéos
            </button>
          </div>
        </div>
        <Offcanvas show={showMenu} onHide={handleCloseMenu} placement={"end"}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Menu</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <MainMenu />
          </Offcanvas.Body>
        </Offcanvas>

        <Offcanvas show={showFilters} onHide={handleCloseFilters}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Filtrer les vidéos</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Filtres />
          </Offcanvas.Body>
        </Offcanvas>
      </nav>
    </>
  );
}

export default Header;
