import React from "react"
import { temoignages } from "../assets/data/data"

function Temoignages() {
	return (
		<section id="temoignages" className="temoignages">
			<div className="container-xxl">
				<div className="row">
					<div className="col text-center">
						<h2>Témoignages</h2>
					</div>
				</div>
				{temoignages.map((temoignage, temoignageIndex) => (
					<div key={temoignageIndex} className="row">
						<div className="col temoignages__container">
							<div className="temoignages__box bg-white pt-2 pe-3 pb-4 ps-2 px-lg-3 mb-3 rounded shadow-sm">
								<h3 className="pt-2 pb-3 text-center">{temoignage.type}</h3>
								<div className="temoignages__loop">
									{temoignage.descriptions.map((description, descriptionIndex) => (
										<div key={descriptionIndex} className="card p-0">
											<blockquote
												dangerouslySetInnerHTML={{
													__html: description,
												}}
												className="temoignages__description p-3"
											></blockquote>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
		</section>
	)
}

export default Temoignages
