export const page = {
	title: "La pleine conscience quotidienne",
	menu: [{ introduction: "Introduction" }, { activites: "Les activités" }, { experts: "Nos experts" }, { temoignages: "Témoignages" }],
}

export const videoIntro = {
	image: "introduction.jpeg",
	lien: "https://vimeo.com/901203148/cff067fc82?share=copy",
}

// titre: Value,
// description: Value,
// type: Value,
// paliers: Value,
// cycles: Value,
// expert: Value,

export const videos = [
	{
		titre: "À la découverte de ma respiration",
		description: "Dans cette activité, l’élève apprend à explorer différentes facettes de sa respiration.",
		image: "A_la_decouverte_de_ma_respiration.png",
		lien: "https://vimeo.com/903820714/3c7e5be147?share=copy",
		duree: 7,
		theme: "Gestion des émotions",
		type: "Technique de respiration",
		cycles: ["Élémentaire", "Primaire 1-3", "Moyen 4-6"],
		expert: "Steven Laureys",
	},
	{
		titre: "Gratitude pour mon corps",
		description: "Chaque partie du corps est visitée pour constater tous les bienfaits qu’elle apporte. Quelle machine incroyable, ce corps&nbsp;!",
		image: "Gratitude_pour_mon_corps.png",
		lien: "https://vimeo.com/894257381/4169b01c05?share=copy",
		duree: 4,
		theme: "Connaissance de soi",
		type: "Technique de relaxation",
		cycles: ["Élémentaire", "Primaire 1-3"],
		expert: "Laurence Mercier",
	},
	{
		titre: "Je respire avec la vague",
		description: "Dans cette activité, l’élève respire selon le rythme des vagues, expirant lorsqu’elles se retirent et inspirant lorsqu’elles reviennent.",
		image: "Je_respire_avec_la_vague.png",
		lien: "https://vimeo.com/896559011/d2a5116791?share=copy",
		duree: 4,
		theme: "Gestion du stress",
		type: "Technique de respiration",
		cycles: ["Élémentaire", "Moyen 4-6", "Intermédiaire 7-8"],
		expert: "Louise Gaudreau",
	},
	{
		titre: "Je respire mes émotions",
		description: "Chaque émotion altère la forme et le rythme de la respiration. Cette activité amène l’élève à ralentir sa respiration, ce qui entraîne une détente de son corps et un apaisement de ses émotions.",
		image: "Je_respire_mes_emotions.png",
		lien: "https://vimeo.com/894256982/e56d6ff99e?share=copy",
		duree: 4,
		theme: "Gestion des émotions",
		type: "Technique de respiration",
		cycles: ["Élémentaire", "Primaire 1-3"],
		expert: "Laurence Mercier",
	},
	{
		titre: "Je suis un nuage",
		description: "Dans cette visualisation, l’élève s’observe devenir un nuage et explore la sensation de flotter dans le ciel, puis de se diluer dans l’atmosphère.",
		image: "Je_suis_un_nuage.png",
		lien: "https://vimeo.com/901171136/3950e2777c?share=copy",
		duree: 9,
		theme: "Gestion du stress",
		type: "Visualisation",
		cycles: ["Secondaire", "Intermédiaire 9-10", "Supérieur 11-12"],
		expert: "Jonathan Lavallée",
	},
	{
		titre: "Je suis un soleil",
		description: "Dans cette visualisation, l’élève imagine être un soleil rayonnant, immense et puissant. Même si le soleil est parfois caché par les nuages, sa nature ne change pas&nbsp;: il demeure rayonnant et puissant.",
		image: "Je_suis_un_soleil.png",
		lien: "https://vimeo.com/905998142/c755672936?share=copy",
		duree: 4,
		theme: "Affirmation de soi",
		type: "Visualisation",
		cycles: ["Élémentaire", "Moyen 4-6"],
		expert: "Antoinette Layoun",
	},
	{
		titre: "La bonne humeur",
		description: "Dans son endroit favori, l’élève s’imagine en train de rire aux éclats et de vivre un total bien-être dans ce lieu parfait.",
		image: "La_bonne_humeur.png",
		lien: "https://vimeo.com/894263163/7a7fbb338a?share=copy",
		duree: 2,
		theme: "Gestion du stress",
		type: "Visualisation",
		cycles: ["Élémentaire", "Intermédiaire 7-8", "Secondaire", "Intermédiaire 9-10", "Supérieur 11-12"],
		expert: "Jonathan Lavallée",
	},
	{
		titre: "La boule à neige",
		description: "En observant l’intérieur d’une boule à neige, l’élève expérimente la différence entre l’agitation du tourbillon de la neige qui représente le chaos de ses pensées et le calme de la neige qui se dépose tout au fond.",
		image: "La_boule_a_neige.png",
		lien: "https://vimeo.com/905997844/e68568abd9?share=copy",
		duree: 5,
		theme: "Gestion des émotions",
		type: "Activité de pleine conscience",
		cycles: ["Élémentaire", "Moyen 4-6", "Intermédiaire 7-8", "Secondaire", "Intermédiaire 9-10", "Supérieur 11-12"],
		expert: "Édith Levasseur",
	},
	{
		titre: "La chute",
		description: "Cette visualisation transporte l’élève dans une forêt tropicale ensoleillée où se trouve une magnifique chute ayant le pouvoir de chasser le stress et de détendre le corps.",
		image: "La_chute.png",
		lien: "https://vimeo.com/894256724/b32f33049b?share=copy",
		duree: 5,
		theme: "Gestion du stress",
		type: "Visualisation",
		cycles: ["Élémentaire", "Intermédiaire 7-8"],
		expert: "Jonathan Lavallée",
	},
	{
		titre: "La cohérence cardiaque rythmée",
		description: "Lorsque la respiration adopte un rythme particulier, le cœur se synchronise avec elle. Ce processus régule le système nerveux autonome, pilote automatique du corps, et permet d’atteindre la cohérence cardiaque. Cette activité guide la respiration vers ce rythme particulier.",
		image: "La_coherence_cardiaque_rythmee.png",
		lien: "https://vimeo.com/906009894/479df00f1c?share=copy",
		duree: 4,
		theme: "Gestion du stress",
		type: "Technique de respiration",
		cycles: ["Élémentaire", "Primaire 1-3", "Moyen 4-6"],
		expert: "Maryse St-Onge",
	},
	{
		titre: "La dégustation",
		description: "Chaque fois que l’on mange constitue une occasion de vivre une expérience de pleine conscience. Cette dégustation permet à l’élève d’explorer en détail les sensations que lui procure l’aliment qu’il mange en pleine présence. <span class='d-block'><strong>À noter&nbsp;:</strong> les élèves doivent avoir une petite collation à manger pour réaliser cette activité.</span>",
		image: "La_degustation.png",
		lien: "https://vimeo.com/894263025/4218c495c0?share=copy",
		duree: 5,
		theme: "Gestion du stress",
		type: "Activité de pleine conscience",
		cycles: ["Élémentaire", "Primaire 1-3", "Moyen 4-6", "Intermédiaire 7-8", "Secondaire", "Intermédiaire 9-10"],
		expert: "Steven Laureys",
	},
	{
		titre: "La force",
		description: "Cette méditation amène l’élève à imaginer que ses pieds grossissent de plus en plus, provoquant un léger tremblement de terre à chaque pas. Chaque respiration augmente sa force intérieure, lui permettant de s’affirmer sainement.",
		image: "La_force.png",
		lien: "https://vimeo.com/901170585/7c4c7f320a?share=copy",
		duree: 3,
		theme: "Affirmation de soi",
		type: "Méditation",
		cycles: ["Secondaire", "Intermédiaire 9-10", "Supérieur 11-12"],
		expert: "Louise Gaudreau",
	},
	{
		titre: "La forêt enchantée",
		description: "Dans la forêt enchantée, l’élève baigne dans un monde merveilleux de sensations dans lequel une parfaite sécurité est ressentie, même si le contrôle de ce lieu ne lui appartient pas.",
		image: "La_foret_enchantee.png",
		lien: "https://vimeo.com/906121134/95bcce4aa1?share=copy",
		duree: 5,
		theme: "Gestion du stress",
		type: "Visualisation",
		cycles: ["Élémentaire", "Moyen 4-6", "Intermédiaire 7-8", "Secondaire", "Intermédiaire 9-10", "Supérieur 11-12"],
		expert: "Maryse St-Onge",
	},
	{
		titre: "La montagne",
		description: "Dans cette visualisation, l’élève fait l’expérience de devenir une montagne solide, ancrée, sûre d’elle-même, forte et immuable.",
		image: "La_montagne.png",
		lien: "https://vimeo.com/893375177/b2e743dc0e?share=copy",
		duree: 4,
		theme: "Affirmation de soi",
		type: "Visualisation",
		cycles: ["Élémentaire", "Primaire 1-3", "Moyen 4-6"],
		expert: "Laurence Mercier",
	},
	{
		titre: "La pierre",
		description: "Cette activité permet à l’élève de transformer une pierre en pense-bête lui rappelant que ses préoccupations et émotions inconfortables vont finir par passer. <span class='d-block'><strong>À noter&nbsp;:</strong> les élèves doivent avoir une petite pierre en main pour faire cette activité. De plus, il est conseillé de valider la compréhension du mot «&nbsp;pense-bête&nbsp;» avant de débuter la capsule puisqu’il s’agit d’un mot qui n’est peut-être pas connu de toute la classe.</span>",
		image: "La_pierre.png",
		lien: "https://vimeo.com/905998294/05ea1fcd47?share=copy",
		duree: 5,
		theme: "Gestion des émotions",
		type: "Méditation",
		cycles: ["Élémentaire", "Primaire 1-3", "Moyen 4-6"],
		expert: "Steven Laureys",
	},
	{
		titre: "La trousse de premiers soins",
		description: "Cette méditation est à utiliser lorsque le niveau de stress est élevé dans la classe, par exemple avant une tâche d’évaluation écrite ou une présentation orale.",
		image: "La_trousse_de_premiers_soins.png",
		lien: "https://vimeo.com/906003232/8213b43876?share=copy",
		duree: 5,
		theme: "Gestion du stress",
		type: "Méditation",
		cycles: ["Élémentaire", "Moyen 4-6", "Intermédiaire 7-8", "Secondaire", "Intermédiaire 9-10", "Supérieur 11-12"],
		expert: "Maryse St-Onge",
	},
	{
		titre: "L’arc-en-ciel",
		description: "Cette visualisation invite l’élève à imaginer un arc-en-ciel qui représente tout ce qui est beau en sa personne. Grâce à l’arc-en-ciel, l’appréciation, le respect et la communication avec les autres deviennent confortables et familiers.",
		image: "Larc-en-ciel.png",
		lien: "https://vimeo.com/896559164/7af4545649?share=copy",
		duree: 3,
		theme: "Relations saines",
		type: "Méditation",
		cycles: ["Élémentaire", "Moyen 4-6", "Intermédiaire 7-8", "Secondaire", "Intermédiaire 9-10", "Supérieur 11-12"],
		expert: "Louise Gaudreau",
	},
	{
		titre: "L’aspirateur",
		description: "Cette visualisation invite l’élève à grimper sur une échelle qui mène au-dessus des nuages, où se trouve un aspirateur magique capable d’aspirer la colère et les frustrations accumulées.",
		image: "Laspirateur.png",
		lien: "https://vimeo.com/896558721/bf29ffac1a?share=copy",
		duree: 4,
		theme: "Gestion des émotions",
		type: "Visualisation",
		cycles: ["Élémentaire", "Primaire 1-3"],
		expert: "Louise Gaudreau",
	},
	{
		titre: "L’autoroute",
		description: "Dans cette activité, les pensées sont représentées comme des voitures filant sur une autoroute. L’élève observe la couleur et la vitesse des voitures qui passent, sans les juger, puis fait de même avec ses pensées.",
		image: "Lautoroute.png",
		lien: "https://vimeo.com/905998572/cb635dad3e?share=copy",
		duree: 5,
		theme: "Gestion du stress",
		type: "Activité de pleine conscience",
		cycles: ["Élémentaire", "Moyen 4-6", "Intermédiaire 7-8", "Secondaire", "Intermédiaire 9-10", "Supérieur 11-12"],
		expert: "Édith Levasseur",
	},
	{
		titre: "Le ballon",
		description: "Dans cette méditation, l’élève imagine un ballon entre ses mains. Ses préoccupations et ses émotions difficiles sont enfermées dans le ballon avant que celui-ci ne soit relâché.",
		image: "Le_ballon.png",
		lien: "https://vimeo.com/894256420/0706807254?share=copy",
		duree: 4,
		theme: "Gestion des émotions",
		type: "Méditation",
		cycles: ["Élémentaire", "Moyen 4-6", "Intermédiaire 7-8", "Secondaire", "Intermédiaire 9-10", "Supérieur 11-12"],
		expert: "Jonathan Lavallée",
	},

	{
		titre: "Le corps qui se transforme",
		description: "Le corps modifie sa posture selon les émotions qui le traversent. En prenant conscience de sa posture et en la modifiant volontairement, l’élève apprend à influencer positivement son état intérieur et à se détendre.",
		image: "Le_corps_qui_se_transforme.png",
		lien: "https://vimeo.com/905998496/6a43f77d1b?share=copy",
		duree: 6,
		theme: "Gestion des émotions",
		type: "Technique de relaxation",
		cycles: ["Élémentaire", "Primaire 1-3", "Moyen 4-6"],
		expert: "Antoinette Layoun",
	},
	{
		titre: "Le feu",
		description: "L’observation d’un feu qui crépite est très relaxante. Cette activité permet de le faire en pleine conscience.",
		theme: "Gestion du stress",
		lien: "https://vimeo.com/905997975/8cfaa93872?share=copy",
		duree: 4,
		image: "Le_feu.png",
		type: "Technique de relaxation",
		cycles: ["Élémentaire", "Primaire 1-3", "Moyen 4-6"],
		expert: "Steven Laureys",
	},
	{
		titre: "Le lion rugissant",
		description: "Dans cette activité, l’élève apprend à maîtriser une technique qui permet de relâcher très efficacement les tensions dans la poitrine en étirant les muscles du cou, de la langue et du visage. Cette activité peut déclencher des fous rires et c’est bien normal&nbsp;!",
		image: "Le_lion_rugissant.png",
		lien: "https://vimeo.com/901170996/984d0c390c?share=copy",
		duree: 3,
		theme: "Gestion du stress",
		type: "Technique de relaxation",
		cycles: ["Élémentaire", "Primaire 1-3"],
		expert: "Steven Laureys",
	},
	{
		titre: "Le ménage",
		description: "La pleine conscience peut être expérimentée à tout moment de la journée, même en faisant du ménage! Cette activité guide l’élève vers l’exploration en toute conscience de son coffre à crayons ou autre récipient. <span class='d-block'><strong>À noter&nbsp;:</strong> un coffre à crayon, une boîte à lunch ou un autre récipient contenant plusieurs petits articles doit être placé devant l’élève.</span>",
		lien: "https://vimeo.com/905998617/8114bd6469?share=copy",
		duree: 7,
		image: "Le_menage.png",
		theme: "Gestion du stress",
		type: "Activité de pleine conscience",
		cycles: ["Élémentaire", "Moyen 4-6", "Intermédiaire 7-8"],
		expert: "Édith Levasseur",
	},
	{
		titre: "Le miroir",
		description: "L’élève s’imagine dans une chambre, devant un miroir. En se regardant avec bienveillance, il lui est possible de cultiver son amour-propre et d’apprendre à apprécier sa beauté et son authenticité.",
		image: "Le_miroir.png",
		lien: "https://vimeo.com/901170926/dc3608663b?share=copy",
		duree: 3,
		theme: "Connaissance de soi",
		type: "Visualisation",
		cycles: ["Secondaire", "Intermédiaire 9-10", "Supérieur 11-12"],
		expert: "Jonathan Lavallée",
	},
	{
		titre: "Le refuge intérieur",
		description: "Dans cette méditation, l’élève retrouve son espace de calme intérieur où il est toujours possible de se réfugier lorsque la tempête fait rage, pour y déposer les pensées, les émotions et les sensations inconfortables.",
		image: "Le_refuge_interieur.png",
		lien: "https://vimeo.com/894257270/2a7da3cfc4?share=copy",
		duree: 4,
		theme: "Gestion des émotions",
		type: "Méditation",
		cycles: ["Élémentaire", "Primaire 1-3"],
		expert: "Laurence Mercier",
	},
	{
		titre: "Le sablier",
		description: "En observant le sable s’écouler dans un sablier, l’élève vit pleinement le moment présent et réalise que l’esprit a souvent tendance à vivre dans le passé ou le futur.",
		image: "Le_sablier.png",
		lien: "https://vimeo.com/903820560/07ccbd9319?share=copy",
		duree: 6,
		theme: "Gestion du stress",
		type: "Activité de pleine conscience",
		cycles: ["Élémentaire", "Moyen 4-6", "Intermédiaire 7-8", "Secondaire", "Intermédiaire 9-10", "Supérieur 11-12"],
		expert: "Édith Levasseur",
	},
	{
		titre: "Respiration en étoile",
		description: "L’élève visualise l’air circulant dans son corps à travers ses cinq branches : ses jambes, ses bras et le sommet de sa tête, comme dans des tubes.",
		image: "La_respiration_en_etoile.png",
		lien: "https://vimeo.com/894264672/2c65d8a4df?share=copy",
		duree: 14,
		theme: "Gestion du stress",
		type: "Technique de respiration",
		cycles: ["Secondaire", "Intermédiaire 9-10", "Supérieur 11-12"],
		expert: "Jonathan Lavallée",
	},
	{
		titre: "Les affirmations positives",
		description: "Cette méditation guide l’élève vers la création d’un discours intérieur bénéfique à l’aide d’affirmations positives à se répéter mentalement.",
		image: "Les_affirmations_positives.png",
		lien: "https://vimeo.com/903820909/202ce343d5?share=copy",
		duree: 5,
		theme: "Affirmation de soi",
		type: "Méditation",
		cycles: ["Secondaire", "Intermédiaire 9-10", "Supérieur 11-12"],
		expert: "Maryse St-Onge",
	},
	{
		titre: "L’héroïne et le héros",
		description: "Dans cette méditation, l’élève devient une héroïne ou un héros qui accueille avec courage sa peur et son inconfort.",
		image: "Lheroine_et_le_hero.png",
		lien: "https://vimeo.com/894257474/7f65b0fcd2?share=copy",
		duree: 4,
		theme: "Gestion des émotions",
		type: "Méditation",
		cycles: ["Élémentaire", "Moyen 4-6"],
		expert: "Laurence Mercier",
	},
	{
		titre: "Mes émotions tiennent dans le creux de ma main",
		description: "Dans cette méditation, l’élève observe le tourbillon de ses émotions dans son ventre, puis le fait rapetisser jusqu’à le tenir dans le creux de sa main.",
		image: "Mes_emotions_tiennent_dans_le_creux_de_ma_main.png",
		lien: "https://vimeo.com/901170837/94d6c5f705?share=copy",
		duree: 12,
		theme: "Gestion des émotions",
		type: "Méditation",
		cycles: ["Secondaire", "Intermédiaire 9-10", "Supérieur 11-12"],
		expert: "Louise Gaudreau",
	},
	{
		titre: "Respiration nettoyante",
		description: "Cette technique de respiration permet d’évacuer sainement les émotions douloureuses en inspirant une lumière blanche et en expirant un nuage gris contenant tout ce qui n’est plus utile à l’élève.",
		image: "Respiration_nettoyante.png",
		lien: "https://vimeo.com/905998425/f40a8693c1?share=copy",
		duree: 5,
		theme: "Gestion des émotions",
		type: "Technique de respiration",
		paliers: [""],
		cycles: ["Élémentaire", "Primaire 1-3", "Moyen 4-6"],
		expert: "Antoinette Layoun",
	},
	{
		titre: "Visite guidée de mon corps",
		description: "Par ce balayage corporel complet, chaque membre du corps est détendu, étape par étape, en leur portant attention un à la fois.",
		image: "Visite_guidee_de_mon_corps.png",
		lien: "https://vimeo.com/896559308/217dbd2477?share=copy",
		duree: 12,
		theme: "Gestion du stress",
		type: "Technique de relaxation",
		cycles: ["Élémentaire", "Moyen 4-6", "Intermédiaire 7-8", "Secondaire", "Intermédiaire 9-10"],
		expert: "Maryse St-Onge",
	},
]

export const themes = [
	{
		titre: "Affirmation de soi",
		description: "Les activités qui touchent au thème de l’affirmation de soi aident à développer sa confiance en soi et à exprimer ses besoins et ses opinions de manière positive.",
	},
	{
		titre: "Connaissance de soi",
		description: "Les activités qui touchent au thème de la connaissance de soi encouragent une exploration positive de l’identité personnelle.",
	},
	{
		titre: "Gestion des émotions",
		description: "Les activités qui touchent au thème de la gestion des émotions aident l’enfant à identifier, à comprendre et à maîtriser sainement ses émotions.",
	},
	{
		titre: "Gestion du stress",
		description: "Les activités qui touchent au thème de la gestion du stress offrent des outils et des techniques qui visent la réduction de l’anxiété et du stress. ",
	},
	{
		titre: "Relations saines",
		description: "Les activités qui touchent au thème des relations saines favorisent le respect, l’inclusion, les interactions positives et la bienveillance.",
	},
]
export const types = [
	{
		titre: "Activité de pleine conscience",
		description: "Les activités de pleine conscience proposent des exercices actifs.",
	},
	{
		titre: "Méditation",
		description: "Les activités de méditation guident la pensée et dirigent l’attention vers un sujet particulier.",
	},
	{
		titre: "Technique de relaxation",
		description: "Les activités de technique de relaxation permettent de relâcher les tensions corporelles et d’apaiser l’esprit.",
	},
	{
		titre: "Technique de respiration",
		description: "Les activités de technique de respiration proposent des exercices dans lesquels la respiration est le principal sujet d’attention.",
	},
	{
		titre: "Visualisation",
		description: "Les activités de visualisation offrent un voyage dans l’imaginaire.",
	},
]

export const paliers = [
	{
		titre: "Élémentaire",
		description: "Ces activités sont de courte durée. Bien qu’elles présentent un attrait visuel pour les plus jeunes enfants, les élèves du secondaire peuvent également en tirer parti.",
		cycles: ["Primaire 1-3", "Moyen 4-6", "Intermédiaire 7-8"],
	},
	{
		titre: "Secondaire",
		description: "Ces activités de plus longue durée abordent des sujets se destinant à des jeunes au développement plus avancé. Les élèves de l’élémentaire ayant l’habitude des activités de pleine conscience peuvent toutefois en tirer parti si la personne responsable de la classe juge son contenu approprié.",
		cycles: ["Intermédiaire 9-10", "Supérieur 11-12"],
	},
]

export const experts = [
	{
		image: "antoinette-layoun.png",
		nom: "Antoinette Layoun",
		description: "Formatrice HeartMath, thérapeute spécialisée depuis 1997, maître en yoga, auteure et conférencière, Antoinette Layoun est une spécialiste établie de la méditation, de la cohérence cardiaque et d’autres méthodes de pleine conscience. Elle possède une vaste expérience d’animation d’activités de pleine conscience auprès de groupes d’adultes et d’enfants.",
	},
	{
		image: "edith-levasseur.png",
		nom: "Édith Levasseur",
		description: "Formatrice, conférencière, auteure, professeure d’impact et enseignante depuis plus de 20 ans, Édith Levasseur est à la tête de l’entreprise Génération IPC qui encourage l’utilisation quotidienne des techniques d’impact, de la pleine conscience et de rituels de classe afin d’accompagner les enfants vers une saine gestion de leurs émotions.",
	},
	{
		image: "jonathan-lavallee.png",
		nom: "Jonathan Lavallée",
		description: "Entraîneur physique, spécialiste de tai-chi, de yoga, de pilates et de nombreuses autres techniques, il est aussi spécialiste de méditation, ce qui l’a amené au fil des ans à donner de nombreux cours et activités de méditation auprès de groupes d’adultes et d’enfants. Il est l’auteur du livre <em>Méditations guidées</em>.",
	},
	{
		image: "laurence-mercier.png",
		nom: "Laurence Mercier",
		description: "Laurence Mercier a un doctorat en génie. Elle se consacre depuis 2014 à sa passion de l’enseignement de la pleine conscience et de la méditation. Elle possède sa certification d’enseignement du Qi Gong depuis 2022. Elle détient une vaste expérience comme formatrice et animatrice auprès des équipes-écoles et des élèves de l’élémentaire et du secondaire.",
	},
	{
		image: "louise-gaudreau.png",
		nom: "Louise Gaudreau",
		description: "Après un baccalauréat en psychologie et des études en psychoéducation, Louise Gaudreau se consacre entièrement à son entreprise Un cœur à fleurir spécialisée en méditations pour enfants et adultes. Elle est l’auteure de plusieurs CD de méditations pour enfants et est auteure de certaines méditations du programme numérique Maître zen.",
	},
	{
		image: "maryse-st-onge.png",
		nom: "Maryse St-Onge",
		description: "Artiste professionnelle, auteure, illustratrice, Maryse est à la tête de Immuart où elle enseigne la musique, le chant, la danse, le yoga et les arts en milieu préscolaire et scolaire. Elle est également l’auteure du projet de méditation pour enfants <em>Sentier de plumes</em> qui propose un CD et des activités animées.",
	},
	{
		image: "steven-laureys.png",
		nom: "Steven Laureys",
		description: "Neurologue connu mondialement pour son étude scientifique de la conscience et des perceptions, des pensées et des émotions, le Dr Steven Laureys est directeur de la recherche au Fonds national de la recherche scientifique et membre de l’Académie royale de Belgique. Il dirige l’unité de recherche sur la conscience au Centre de recherche GIGA de l’Université de Liège et du CHU de Liège. Il est l’auteur de nombreux livres dont <em>Méditer avec le Dr Steven Laureys</em>. Il habite désormais au Québec, où il enseigne, et est rattaché au centre CERVO de l’Université Laval. ",
	},
]

export const temoignages = [
	{
		type: "Élèves",
		descriptions: ["Après avoir fait une activité, j’ai beaucoup plus de patience et je suis plus calme. Ça permet d’apprécier les petites choses.", "Après avoir fait une activité, je me sens très détendue et prête à retourner au travail en pleine conscience.", "Une capsule m’a permis de ressentir de la confiance en soi, ce qui est utile pour faire plus de choses et d’activités.", "Les activités aident la classe à se calmer avant un test ou un travail. Elles apportent énormément de bien à tout le monde. J’adorerais continuer à les faire !", "Selon moi, la pleine conscience est une façon de relaxer et d’ignorer ce qui se passe autour de soi ou ce que les autres pensent."],
	},
	{
		type: "Membres du personnel enseignant",
		descriptions: ["Je suis plus sereine pour mes enseignements depuis que j’ai commencé à utiliser la plateforme. Elle sera un outil très utile dans ma salle de classe.", "Même lorsque le niveau d’énergie de la classe est élevé, j’observe définitivement un retour au calme lors du visionnement d’une capsule.", "Nous avons eu de belles discussions à la suite de l’écoute de certaines capsules qui venaient toucher à des cordes sensibles.", "J’observe que les élèves sont plus calmes et plus réceptifs lors de l’enseignement et l’exécution des tâches depuis que j’ai commencé à utiliser la plateforme.", "Les capsules me permettent à moi aussi de respirer et de me calmer durant les périodes surchargées. Je vais continuer à utiliser la plateforme.", "C’est facile de naviguer sur la plateforme. Les capsules sont bien exécutées. Les couleurs et les voix nous invitent au calme et à la relaxation.", "J’aime la simplicité et l’accessibilité de la plateforme. Il est simple de cibler le bon groupe d’âge tout en nous laissant naviguer parmi d’autres activités de groupe d’âges différents.", "Nous avons visionné les capsules dans le cadre du module sur la santé mentale que nous explorons maintenant en éducation physique et santé."],
	},
]
