import React, { useContext, useEffect, useState } from "react"

import { videos } from "../assets/data/data.js"

import Video from "./Video"

import StateContext from "../StateContext"

function Theme(props) {
	const appState = useContext(StateContext)

	const [visible, setVisible] = useState(true)

	const [hasVisibleVideos, setHasVisibleVideos] = useState(true)

	useEffect(() => {
		// Check if theme should be visible
		if (!appState.filters.theme || appState.filters.theme.value === props.titre) setVisible(true)
		else setVisible(false)

		// Check if theme has any visible video, otherwise display a message.
		let visibleVideosLength = 0
		for (let i = 0; i < videos.length; i++) {
			if (videos[i].theme === props.titre && videos[i].lien) {
				if ((!appState.filters.type || appState.filters.type.value === videos[i].type) && (!appState.filters.palier || videos[i].cycles.includes(appState.filters.palier.value)) && (!appState.filters.expert || appState.filters.expert.value === videos[i].expert)) visibleVideosLength++
			}
		}
		if (visibleVideosLength > 0) setHasVisibleVideos(true)
		else setHasVisibleVideos(false)

		if (appState.triCounter > 0) {
			props.activitesRef.current.scrollIntoView({
				behavior: "smooth",
				block: "start",
			})
		}
		// eslint-disable-next-line
	}, [appState.triCounter])

	return (
		<>
			{visible ? (
				<div className="activites__box bg-white p-3 mb-3 rounded shadow-sm">
					<h3 className="pt-2 pb-3 text-center">{props.titre}</h3>
					<div className="videos__loop">{hasVisibleVideos ? videos.map((video, videoIndex) => (video.theme === props.titre ? <Video key={videoIndex} titre={video.titre} description={video.description} image={video.image} lien={video.lien} duree={video.duree} theme={props.titre} type={video.type} paliers={video.paliers} cycles={video.cycles} expert={video.expert} /> : null)) : <p className="p-3 pt-0 w-100 text-center">Aucune vidéo ne correspond aux filtres.</p>}</div>
				</div>
			) : null}
		</>
	)
}

export default Theme
