import { useImmerReducer } from "use-immer"

// Context
import StateContext from "./StateContext"
import DispatchContext from "./DispatchContext"

import "./assets/scss/App.scss"

import Header from "./components/Header"
import Introduction from "./components/Introduction"
import Activites from "./components/Activites"
import Experts from "./components/Experts"
import Temoignages from "./components/Temoignages"
import Footer from "./components/Footer"

function App() {
	const initialState = {
		filters: {
			theme: null,
			type: null,
			palier: null,
			expert: null,
		},
		triCounter: 0,
	}

	function appReducer(draft, action) {
		switch (action.type) {
			case "SET_THEME":
				draft.filters.theme = action.value
				break
			case "SET_TYPE":
				draft.filters.type = action.value
				break
			case "SET_PALIER":
				draft.filters.palier = action.value
				break
			case "SET_EXPERT":
				draft.filters.expert = action.value
				break
			case "INCREASE_TRI_COUNTER":
				draft.triCounter = draft.triCounter + 1
				break
		}
	}

	const [appState, appDispatch] = useImmerReducer(appReducer, initialState)

	return (
		<StateContext.Provider value={appState}>
			<DispatchContext.Provider value={appDispatch}>
				<div className="App">
					<Header />
					<Introduction />
					<Activites />
					<Experts />
					<Temoignages />
					<Footer />
				</div>
			</DispatchContext.Provider>
		</StateContext.Provider>
	)
}

export default App
