import React, { useContext } from "react";

import Accordion from "react-bootstrap/Accordion";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionButton } from "react-bootstrap/AccordionButton";

import { experts } from "../assets/data/data";

function ContextAwareToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <button
      type="button"
      className={`experts__btn ${isCurrentEventKey ? "active" : ""}`}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}

function Experts() {
  return (
    <section id="experts" className="experts">
      <div className="container-xxl">
        <div className="row">
          <div className="col">
            <h2 className="text-center">Nos experts</h2>
            <div className="row experts__row gy-4">
              {experts.map((expert, expertIndex) => (
                <div key={expertIndex} className="experts__col">
                  <div className="card py-2 px-3 p-md-3">
                    <div className="d-flex d-md-none flex-column align-items-center">
                      <Accordion>
                        <ContextAwareToggle eventKey={expertIndex}>
                          <img
                            className="rounded-circle"
                            src={require(`../assets/img/experts/${expert.image}`)}
                            width="128"
                            height="128"
                            alt=""
                          />
                          <h3 className="experts__nom">{expert.nom}</h3>
                        </ContextAwareToggle>
                        <Accordion.Collapse eventKey={expertIndex}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: expert.description,
                            }}
                            className="mt-3 mb-2"
                          ></div>
                        </Accordion.Collapse>
                      </Accordion>
                    </div>
                    <div className="d-none d-md-flex flex-column align-items-center">
                      <img
                        className="rounded-circle"
                        src={require(`../assets/img/experts/${expert.image}`)}
                        width="128"
                        height="128"
                        alt=""
                      />
                      <h3 className="experts__nom">{expert.nom}</h3>
                      <div
                        dangerouslySetInnerHTML={{ __html: expert.description }}
                      ></div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Experts;
