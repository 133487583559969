import React, { useContext, useRef } from "react";
import Select from "react-select";

import StateContext from "../StateContext";
import DispatchContext from "../DispatchContext";

import { paliers, experts, types, themes } from "../assets/data/data";

function Filtres() {
  const appState = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);

  let selectedThemeRef = useRef(null);
  let selectedTypeRef = useRef(null);
  let selectedPalierRef = useRef(null);
  let selectedExpertRef = useRef(null);

  function resetFilters() {
    selectedThemeRef.current.clearValue();
    selectedTypeRef.current.clearValue();
    selectedPalierRef.current.clearValue();
    selectedExpertRef.current.clearValue();
    appDispatch({
      type: "INCREASE_TRI_COUNTER",
    });
    appDispatch(
      {
        type: "SET_THEME",
        value: null,
      },
      {
        type: "SET_TYPE",
        value: null,
      },
      {
        type: "SET_PALIER",
        value: null,
      },
      {
        type: "SET_EXPERT",
        value: null,
      }
    );
  }

  function getPalierDescription() {
    let palierParent = "";
    if (appState.filters.palier) {
      for (let i = 0; i < paliers.length; i++) {
        if (paliers[i].cycles.includes(appState.filters.palier.value)) {
          palierParent = paliers[i];
        }
      }
    }
    return palierParent.description;
  }

  return (
    <>
      <Select
        aria-label={"Thème"}
        options={themes.map((theme) => ({
          label: theme.titre,
          value: theme.titre,
        }))}
        onChange={(choice) =>
          appDispatch({
            type: "SET_THEME",
            value: choice ? choice : null,
          })
        }
        placeholder={"Thème"}
        value={appState.filters.theme ? appState.filters.theme : null}
        ref={selectedThemeRef}
        isClearable={true}
        theme={(theme) => ({
          ...theme,
          borderRadius: "var(--bs-border-radius)",
          colors: {
            ...theme.colors,
            primary: "#e6b59e",
            primary25: "rgba(205, 106, 60, 0.25)",
            neutral20: "var(--bs-border-color)",
            neutral30: "var(--bs-border-color)",
            neutral50: "#666", // Placeholder color
          },
        })}
        styles={{
          dropdownIndicator: (base) => ({
            ...base,
            color: "#191817",
          }),
        }}
      />
      <div className="theme__description mt-2">
        {appState.filters.theme
          ? themes.find((item) => item.titre === appState.filters.theme.value)
              .description
          : ""}
      </div>

      <Select
        aria-label={"Type"}
        options={types.map((type) => ({
          label: type.titre,
          value: type.titre,
        }))}
        onChange={(choice) =>
          appDispatch({
            type: "SET_TYPE",
            value: choice ? choice : null,
          })
        }
        placeholder={"Type"}
        value={appState.filters.type ? appState.filters.type : null}
        ref={selectedTypeRef}
        isClearable={true}
        className="mt-3"
        theme={(theme) => ({
          ...theme,
          borderRadius: "var(--bs-border-radius)",
          colors: {
            ...theme.colors,
            primary: "#e6b59e",
            primary25: "rgba(205, 106, 60, 0.25)",
            neutral20: "var(--bs-border-color)",
            neutral30: "var(--bs-border-color)",
            neutral50: "#666", // Placeholder color
          },
        })}
        styles={{
          dropdownIndicator: (base) => ({
            ...base,
            color: "#191817",
          }),
        }}
      />
      <div className="type__description mt-2">
        {appState.filters.type
          ? types.find((item) => item.titre === appState.filters.type.value)
              .description
          : ""}
      </div>

      <Select
        aria-label={"Palier"}
        options={paliers.map((palier) => ({
          label: palier.titre,
          options: palier.cycles.map((cycle) => ({
            value: cycle,
            label: cycle,
          })),
        }))}
        onChange={(choice) =>
          appDispatch({
            type: "SET_PALIER",
            value: choice ? choice : null,
          })
        }
        placeholder={"Palier"}
        value={appState.filters.palier ? appState.filters.palier : null}
        ref={selectedPalierRef}
        isClearable={true}
        className="mt-3"
        theme={(theme) => ({
          ...theme,
          borderRadius: "var(--bs-border-radius)",
          colors: {
            ...theme.colors,
            primary: "#e6b59e",
            primary25: "rgba(205, 106, 60, 0.25)",
            neutral20: "var(--bs-border-color)",
            neutral30: "var(--bs-border-color)",
            neutral50: "#666", // Placeholder color
          },
        })}
        styles={{
          dropdownIndicator: (base) => ({
            ...base,
            color: "#191817",
          }),
        }}
      />

      <div className="type__description mt-2">
        {appState.filters.palier ? getPalierDescription() : ""}
      </div>

      <Select
        aria-label={"Expert"}
        options={experts.map((expert) => ({
          label: expert.nom,
          value: expert.nom,
        }))}
        onChange={(choice) =>
          appDispatch({
            type: "SET_EXPERT",
            value: choice ? choice : null,
          })
        }
        placeholder={"Expert"}
        value={appState.filters.expert ? appState.filters.expert : null}
        ref={selectedExpertRef}
        isClearable={true}
        className="mt-3"
        theme={(theme) => ({
          ...theme,
          borderRadius: "var(--bs-border-radius)",
          colors: {
            ...theme.colors,
            primary: "#e6b59e",
            primary25: "rgba(205, 106, 60, 0.25)",
            neutral20: "var(--bs-border-color)",
            neutral30: "var(--bs-border-color)",
            neutral50: "#666", // Placeholder color
          },
        })}
        styles={{
          dropdownIndicator: (base) => ({
            ...base,
            color: "#191817",
          }),
        }}
      />

      <div className="mt-3 d-flex justify-content-between">
        <button
          className="btn filters__btn-clear"
          onClick={() => resetFilters()}
        >
          Effacer
        </button>
        <button
          className="btn btn-info filters__btn-trier rounded-5 shadow-sm"
          onClick={() =>
            appDispatch({
              type: "INCREASE_TRI_COUNTER",
            })
          }
        >
          Trier
        </button>
      </div>
    </>
  );
}

export default Filtres;
