import React, { useContext, useEffect, useRef } from "react";
import StateContext from "../StateContext";
import DispatchContext from "../DispatchContext";

// Data
import { themes } from "../assets/data/data";

// Components
import Filtres from "./Filtres";
import Theme from "./Theme";

function Activites() {
  const appState = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);

  const activitesRef = useRef(null);

  return (
    <section id="activites" ref={activitesRef}>
      <div className="container-xxl">
        <div className="row">
          <div className="col col-lg-9 offset-lg-3 text-center">
            <h2>Les activités</h2>
          </div>
        </div>
        <div className="row">
          <aside id="filter-nav" className="d-none d-lg-block col-auto">
            <div className="sticky-top pb-3">
              <div className="filters bg-white rounded-3 shadow-sm">
                <h3 className="filters__title bg-info rounded-3 p-3">
                  Filtrer les vidéos
                </h3>
                <div className="px-3 py-4">
                  <Filtres />
                </div>
              </div>
            </div>
          </aside>
          <div className="col activites__container">
            {themes.map((theme, themeIndex) => (
              <Theme
                key={themeIndex}
                titre={theme.titre}
                activitesRef={activitesRef}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Activites;
