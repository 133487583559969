import React, { useEffect, useState, useContext } from "react"

import Fancybox from "./Fancybox"

import StateContext from "../StateContext"

function Video(props) {
	const appState = useContext(StateContext)

	const [visible, setVisible] = useState(true)

	useEffect(() => {
		if ((!appState.filters.type || appState.filters.type.value === props.type) && (!appState.filters.palier || props.cycles.includes(appState.filters.palier.value)) && (!appState.filters.expert || appState.filters.expert.value === props.expert)) setVisible(true)
		else setVisible(false)
		// eslint-disable-next-line
	}, [appState.triCounter])

	return (
		<>
			{visible && props.lien ? (
				<>
					<div className="card">
						<Fancybox>
							<div className="video-thumbnail mb-3" data-fancybox="" href={props.lien}>
								<img className="d-block img-fluid rounded" src={props.image ? require(`../assets/img/thumbnails/${props.image}`) : "https://placehold.co/280x160"} alt="" />
							</div>
						</Fancybox>
						<div className="titre-container">
							<h4>{props.titre}</h4>
							<div className="duree">
								{props.duree}&nbsp;<abbr title="minutes">min</abbr>
							</div>
						</div>
						<div className="mb-2" dangerouslySetInnerHTML={{ __html: props.description }}></div>
						<div className="tags">
							<span className="badge bg-secondary text-black">{props.theme}</span> <span className="badge bg-secondary text-black">{props.type}</span>
							{props.cycles
								? props.cycles.map((cycle, cycleIndex) => (
										<>
											{" "}
											<span key={cycleIndex} className="badge bg-secondary text-black">
												{cycle}
											</span>
										</>
								  ))
								: null}{" "}
							<span className="badge bg-secondary text-black">{props.expert}</span>
						</div>
						<Fancybox>
							<button className="btn btn-primary btn-sm text-white my-3 visually-hidden-focusable" data-fancybox href={props.lien}>
								Afficher la vidéo
							</button>
						</Fancybox>
					</div>
				</>
			) : null}
		</>
	)
}

export default Video
