import React from "react";

function MainMenu() {
  return (
    <ul className="navbar-nav">
      <li className="nav-item">
        <a className="nav-link" href="#introduction">
          Introduction
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="#activites">
          Les activités
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="#experts">
          Nos experts
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="#temoignages">
          Témoignages
        </a>
      </li>
    </ul>
  );
}

export default MainMenu;
