import React, { useRef } from "react"
import { videoIntro } from "../assets/data/data"
import Fancybox from "./Fancybox"

function Introduction() {
	const triggerButton = useRef(null)

	return (
		<>
			<header id="introduction">
				<div className="container-xxl">
					<div className="row align-items-center">
						<div className="col-lg main-title text-center">
							<h1>La pleine conscience quotidienne</h1>
						</div>
						<div className="col-md-8  mx-auto col-lg sub-title text-center">
							<h2 className="pb-2">Introduction</h2>
							<Fancybox>
								<div className="video-thumbnail" data-fancybox href={videoIntro.lien}>
									<img className="d-block img-fluid rounded shadow-sm" src={require(`../assets/img/thumbnails/${videoIntro.image}`)} alt="" />
								</div>
							</Fancybox>
							<Fancybox>
								<button className="btn btn-primary btn-sm text-white my-3 visually-hidden-focusable" ref={triggerButton} data-fancybox href={videoIntro.lien}>
									Afficher la vidéo d’introduction
								</button>
							</Fancybox>
						</div>
					</div>
				</div>
			</header>
			<div className="subheader-spacer" aria-hidden="true"></div>
		</>
	)
}

export default Introduction
