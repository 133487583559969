import React, { useEffect } from "react"
import logoCheneliere from "../assets/img/design/Logo-Cheneliere.svg"

function Footer() {
	const currentYear = new Date().getFullYear()

	return (
		<footer>
			<div className="container-xxl pt-5">
				<div className="row">
					<div className="col col-lg-8">
						<p>Ce projet a été rendu possible grâce à la contribution financière du ministère de l’Éducation de l’Ontario et du gouvernement du Canada dans le cadre de l’Entente Canada-Ontario relative à l’enseignement dans la langue de la minorité et à l’enseignement de la seconde langue officielle.</p>
						<p>À noter que le contenu n’engage que ses auteurs et ne traduit pas nécessairement le point de vue du ministère de l’Éducation de l’Ontario, ni du gouvernement du Canada, ni du Centre de leadership et d’évaluation inc.</p>
					</div>
				</div>
				<div className="row">
					<div className="col col-md-6 col-lg-4 py-2">
						<hr />
					</div>
				</div>
			</div>

			<div className="container-xxl">
				<div className="row">
					<div className="col pb-3">
						<p>
							<strong>La qualité de cette ressource nous tient à cœur.</strong>
						</p>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-6 mb-3 mb-lg-0">
						<p>Pour tout problème ou questionnement en lien avec le contenu de ce site Web, veuillez vous adresser au Centre de Leadership et d’Évaluation par téléphone ou courriel.</p>
						<p>
							<strong>Téléphone :</strong> (613) 747-7021
						</p>
						<p>
							<strong>Courriel :</strong> <a href="mailto:info@lecle.com">info@lecle.com</a>
						</p>
					</div>
					<div className="col-lg-6">
						<p>En cas de coquille dans un texte ou de bogue constaté sur ce site Web, veuillez contacter le soutien technique de TC Média Livres par téléphone, clavardage ou courriel.</p>
						<p>
							<strong>Téléphone :</strong> 1 (877) 471-0002
						</p>
						<p>
							<strong>Clavardage et courriel :</strong>{" "}
							<a href="https://cheneliere.ca/soutien-technique" target="_blank">
								cheneliere.ca/soutien-technique
							</a>
						</p>
					</div>
				</div>
				<div className="row">
					<div className="col col-md-6 col-lg-4 py-2">
						<hr />
					</div>
				</div>
			</div>

			<div className="container-xxl pb-5">
				<div className="row">
					<div className="col-md-6 mb-3 mb-md-0">
						<p>
							<strong>Site Web :</strong> Pige communication <br />
							<strong>Conception graphique :</strong> Nathalie Lemay – Bonheur en vrac <br />
							<strong>Montage vidéo :</strong> Émilie Caron – Bonheur en vrac <br />
							<strong>Conception et coordination :</strong> Marie-Josée Boudreau – Bonheur en vrac <br />
							<strong>Mixage sonore :</strong> Patrice D’Aragon
						</p>
					</div>
					<div className="col-md-6">
						<img className="d-block img-fluid" src={logoCheneliere} alt="Chenelière Éducation" width="130" />
					</div>
				</div>
			</div>
			<div className="copyright p-3">
				<div className="container-xxl">
					<div className="row">
						<div className="col text-center small">
							<p>© TC Média Livres {currentYear}</p>
						</div>
					</div>
				</div>
			</div>
		</footer>
	)
}

export default Footer
